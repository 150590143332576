<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <h5 class="font-weight-bold mb-0">
          <i class="fas fa-globe"></i>
          <span class="ml-2">{{ $t("serviceRegions") | toUpperCase }}</span>
        </h5>
      </div>
      <div class="card-body position-relative" style="min-height: 200px">
        <WeTable
          v-if="ready"
          v-bind:index="false"
          v-bind:data.sync="serviceRegion.countries"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-on:on-action="onAction"
          v-on:on-switch="onSwitch"
        ></WeTable>
        <WeLoading v-else />
      </div>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      tableActions: [],
      columns: [
        { name: "name", th: "Ülke", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
      ],
    };
  },
  methods: {
    ...mapActions("serviceRegion", ["getList", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onSwitch(data) {
      this.isActive({
        id: data.id,
        is_active: data.is_active,
        onSuccess: (result) => {
          this.$toast.success("Durum Güncellendi");
        },
        onError: (error) => {
          this.$toast.error("Durum Güncellenemedi");
        },
      });
    },
  },
  computed: {
    ...mapState(["shared", "serviceRegion"]),
  },
  mounted() {
    this.getList({
      onSuccess: (result) => {
        this.ready = true;
      },
    });
  },
};
</script>
